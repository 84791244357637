import { fifo, positionFixed, positionRelative, single } from './_helpers'
import { steps, gradient } from './_color'

const run = single(steps)
const points = fifo(steps)

const header = document.querySelector('.header')
const canvas = document.querySelector('.canvas')
const context = canvas==null ? null : canvas.getContext('2d')

let fixed
let width
let height
let ratio

let _x
let _y

const init = () => {

	// Same as max-height in header and content CSS
	const minHeight = 750

	const doc = document.documentElement
	const header = document.querySelector('.header')

	const docSize = doc.getBoundingClientRect()
	const headerSize = header.getBoundingClientRect()

	fixed = docSize.height<minHeight ? false : true
	width = headerSize.width
	height = headerSize.height
	ratio = window.devicePixelRatio

	canvas.style.width = `${ width }px`
	canvas.style.height = `${ height }px`

	canvas.width = width * ratio
	canvas.height = height * ratio

}

const update = (e) => {

	// Position calculation is different when hero is fixed
	const { x, y } = fixed===true ? positionFixed(e) : positionRelative(e)

	context.clearRect(0, 0, width * ratio, height * ratio)

	points({

		from: { x: _x, y: _y },
		to: { x, y }

	}).forEach((point, i) => {

		const color = gradient[i]

		context.beginPath()
		context.moveTo(point.from.x * ratio, point.from.y * ratio)
		context.lineTo(point.to.x * ratio, point.to.y * ratio)
		context.strokeStyle = color
		context.lineWidth = 5 * ratio
		context.stroke()

	})

	_x = x
	_y = y

}

// Safari calculates a wrong height for the header
// when calculating the height without a delay.
setTimeout(() => {

	if (canvas==null) return

	init()

	window.addEventListener('resize', init)
	header.addEventListener('mousemove', (e) => run(() => update(e)))

}, 500)