import countBetween from 'count-between'
import { hasClassNames, hsl, alphaGradient } from './_helpers'

const h = [
	160,
	280,
	310,
	340
]

const elem = document.documentElement
const index = countBetween(0, h.length - 1, 0)

let start
let end

export const steps = 25
export let gradient

const update = () => {

	const i = index()

	start = hsl(h[i], 55, 45)
	end = hsl(h[i] - 60, 45, 55)

	gradient = alphaGradient([ start, end ], steps)

	elem.style.setProperty('--start', start)
	elem.style.setProperty('--end', end)

	index(1)

}

elem.addEventListener('click', (e) => {

	const clickable = [ 'canvas', 'header__inner' ]
	const isClickable = hasClassNames(e.target, clickable)

	if (isClickable===false) return

	update()

})

update()