import interpolate from 'color-interpolate'
import alpha from 'color-alpha'

export const esc = (key) => key===27

export const createArray = (length) => Array.apply(null, Array(length))

export const hasClassNames = (elem, classNames) => classNames.filter((className) => elem.classList.contains(className)).length>0

export const hsl = (h, s, l) => `hsl(${ h }, ${ s }%, ${ l }%)`

export const alphaGradient = (colors, num) => {

	const gradient = interpolate(colors)

	return createArray(num).map((_, i, { length }) => {

		// Calculate value between 0 and 1 based on the total length
		const index = (1 / length) * i
		const color = gradient(index)

		return alpha(color, index)

	})

}

export const fifo = (length) => {

	const arr = []

	return (value) => {

		if (value===undefined) return arr
		if (arr.length>=length) arr.shift()

		arr.push(value)

		return arr

	}

}

export const positionFixed = (e) => ({

	x: e.clientX,
	y: e.clientY

})

export const positionRelative = (e) => ({

	x: e.pageX,
	y: e.pageY

})

export const single = (max) => {

	let id
	let iterations

	const loop = (_id, fn) => {

		if (id!==_id) return
		if (max!==undefined && iterations>=max) return

		++iterations

		fn()
		requestAnimationFrame(() => loop(_id, fn))

	}

	return (fn) => {

		id = Symbol()
		iterations = 0

		loop(id, fn)

		return () => id = Symbol()

	}

}

export const image = (href) => {

	const extensions = [
		'.jpg',
		'.jpeg',
		'.png',
		'.gif'
	]

	return extensions.some((extension) => href.endsWith(extension))

}