import * as basicLightbox from 'basiclightbox'
import { positionFixed, image, esc } from './_helpers'

const img = (href) => `<img src="${ href }">`

const origin = (e, instance) => {

	const { y } = positionFixed(e)
	const { height } = document.documentElement.getBoundingClientRect()

	const reduction = 10
	const offset = (y - height / 2) / reduction

	const elem = instance.element()
	const placeholder = elem.querySelector('.basicLightbox__placeholder')

	placeholder.style.setProperty('--y', `${ offset }px`)

}

document.querySelectorAll('a[href]').forEach((elem) => {

	const href = elem.href

	if (image(elem.href)===false) return

	elem.onclick = (e) => {

		const instance = basicLightbox.create(img(href), {
			className: 'lightbox',
			beforeShow: (instance) => origin(e, instance)
		})

		instance.show()

		document.onkeydown = (e) => esc(e.keyCode)===true ? instance.close() : null

		e.preventDefault()
		e.stopPropagation()

	}

})